<template>
  <!-- 改签单 -->
  <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :disabled="!canRefresh||canRefreshChild">
    <OrderInfoUI :canRefresh.sync="canRefreshChild" v-if="load" :order="order" :isChange="true" :peoList="peoList" :confirmLoading="confirmLoading" :cancelLoading="cancelLoading" :phone="phone" @refund="fetchData" @confirm="confirm" @cancel="cancel" />
  </van-pull-refresh>
</template>

<script>
import OrderInfoUI from "./components/PaymentUI";
import {
  flightOrderItemReload,
  confirmFlightOrderChange,
  cancelFlightOrderChange
} from "@/api/aircraft";
import { fetchPhone } from "@/common/config.js";
export default {
  components: { OrderInfoUI },
  data() {
    return {
      phone: "", // 服务商电话
      load: true,
      order: {}, // 订单
      peoList: [], // 人员
      confirmLoading: false, // 按钮转圈圈
      cancelLoading: false,
      isLoading: false, // 刷新
      canRefresh: true, // 可否刷新
      canRefreshChild: true // 可否刷新
    };
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.fetchData(false);
      }, 500);
    },
    // ajax 获取数据
    fetchData(f = true) {
      if (f) this.canRefresh = true;
      this.phone = fetchPhone(this.order.corp); // 更新服务商电话
      if (this.canRefresh)
        flightOrderItemReload({
          orderNo: this.order.orderNo || this.order.orderSerialNo,
          outOrderNo: this.order.outOrderNo,
          corp: this.order.corp
        }).then(response => {
          this.order = response.order;
          let peos = response.orderItem
          peos.forEach(element => {
            let id = element.identityNo
            let insuranceList = response.insuranceOrderItems || [] // 防空值
            let res = insuranceList.filter(e => { return e.identityNo == id })
            element.insurance = res
          });
          this.peoList = peos;
          this.isLoading = false;
          this.canRefresh = false;
          setTimeout(() => {
            this.canRefresh = true;
          }, 30 * 1000);
        });
      else {
        this.$notify({
          type: "warning",
          message: "请勿频繁刷新，请30秒后再次尝试。"
        });
        this.isLoading = false;
      }
    },
    // 出票
    confirm() {
      this.confirmLoading = true;
      confirmFlightOrderChange({
        orderNo: this.order.orderSerialNo,
        outOrderNo: this.order.outOrderNo,
        originalOrderNo: this.order.bz3,
        corp: this.order.corp
      })
        .then(response => {
          // 如果经费不足提示。
          if (!response.success) {
            this.confirmLoading = false;
            return this.$toast.fail(response.msg);
          }
          this.$toast.success("出票成功");
          this.fetchData();
          this.confirmLoading = false;
        })
        .catch(() => {
          this.confirmLoading = false;
        });
    },
    // 取消出票
    cancel() {
      this.cancelLoading = true;
      cancelFlightOrderChange({
        orderNo: this.order.orderSerialNo,
        outOrderNo: this.order.outOrderNo,
        originalOrderNo: this.order.bz3,
        corp: this.order.corp
      })
        .then(response => {
          this.$toast.success("取消成功");
          this.fetchData();
          this.cancelLoading = false;
        })
        .catch(() => {
          this.cancelLoading = false;
        });
    }
  },
  watch: {
    $route(val) {
      this.order = this.$route.query;
      this.fetchData();
    }
  },
  activated() {
    this.peoList = [];
    this.order = {}
    this.isLoading = true;
    window.setTitle("改签单详情");
    this.order = this.$route.query;
    this.fetchData();
  }
};
</script>